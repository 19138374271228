import { z } from 'zod'

export const CoordinatesSchema = z.object({
  latitude: z.number(),
  longitude: z.number(),
})
export type Coordinates = z.infer<typeof CoordinatesSchema>

// clamp precision to improve caching and reduce unnecessary overhead
// https://xkcd.com/2170
export const MAX_COORDINATE_PRECISION = 4

export const ClampedCoordinateSchema = CoordinatesSchema.transform(
  ({ latitude, longitude }) => ({
    latitude: Number(latitude.toFixed(MAX_COORDINATE_PRECISION)),
    longitude: Number(longitude.toFixed(MAX_COORDINATE_PRECISION)),
  }),
)
