import { formatISO } from 'date-fns'
import { nanoid } from 'nanoid'
import objectHash from 'object-hash'
import { z } from 'zod'
import { type AnonymousUser } from '#server/auth/anonymous.server.ts'
import { GenericEventDataSchema } from '#server/collaboration/collaboration.ts'
import { CoordinatesSchema } from '#server/geolocation/geolocation.ts'
import { type CollaboratorUser } from '#server/user.ts'
import { DateRangeFormSchema } from '../date'
import {
  ItineraryMetadataSchema,
  ItineraryVisibility,
  WithMongoIdSchema,
} from './models'

export const ITINERARY_EDIT_REQUEST = 'itinerary:edit-request'
export const ITINERARY_UPDATE = 'itinerary:update'

/**
 * Document Schema
 */
const WithIndexSchema = z.object({ index: z.number().int().min(0) })
function createOrderedRecordSchema<Schema extends z.AnyZodObject>(
  schema: Schema | Schema[],
) {
  return z.record(
    z.string(),
    Array.isArray(schema)
      ? z.union(
          schema.map(sch => sch.merge(WithIndexSchema)) as unknown as readonly [
            z.ZodTypeAny,
            z.ZodTypeAny,
            ...z.ZodTypeAny[],
          ],
        )
      : WithIndexSchema.merge(schema),
  )
}

/**
 * TODO (andrew): consider locality agnostic address format
 *
 * addressLine1
 * addressLine2
 * locality (a.k.a. city/municipality)
 * region (a.k.a. state/province)
 * postCode (a.k.a. zipcode)
 * country
 */
export const AddressFormSchema = z.object({
  addressLine1: z.string().nullish(),
  addressLine2: z.string().nullish(),
  city: z.string().nullish(),
  state: z.string().nullish(),
  zipCode: z.string().nullish(),
  country: z.string().nullish(),
})
export type AddressDeterminants = z.infer<typeof AddressFormSchema> & {
  [key: string]: string | null | undefined
}

/**
 * Editable Schema
 */

export const ItineraryHeaderSchema = z.object({
  title: z.string(),
  dateRange: DateRangeFormSchema.nullish(),
})

export const ItineraryPlanCommentSchema = z.object({
  id: z.string().default(() => nanoid()),
  createdBy: z.string(),
  createdAt: z.number(),
  updatedAt: z.number().nullish(),
  replyTo: z.string().nullish(),
  comment: z
    .string()
    .min(1, { message: 'Comment cannot be saved empty' })
    .max(280, { message: 'Comment cannot be longer than 280 characters' })
    .nullish(),
})
export type ItineraryPlanComment = z.infer<typeof ItineraryPlanCommentSchema>

export type Commenter =
  | CollaboratorUser
  | (AnonymousUser & { userAvatar?: undefined })

const flightNumberErrorMessage = 'Flight number must be between 1 and 9999'
const ItineraryFlightSchema = z.object({
  id: z.string(),
  airlineCode: z.string(),
  flightNumber: z.coerce
    .number()
    .int()
    .min(1, {
      message: flightNumberErrorMessage,
    })
    .max(9999, {
      message: flightNumberErrorMessage,
    })
    .nullish(),
  departureDate: z
    .union([z.string(), z.date()])
    .transform(arg => (typeof arg === 'string' ? arg : formatISO(arg)))
    .nullish(),
})
export type ItineraryFlight = z.infer<typeof ItineraryFlightSchema>

export const ItineraryFlightRecordSchema = createOrderedRecordSchema(
  ItineraryFlightSchema,
)
export type ItineraryDocumentFlights = z.infer<
  typeof ItineraryFlightRecordSchema
>
export const ItineraryFlightItemSchema = ItineraryFlightRecordSchema.valueSchema
export type ItineraryFlightItem = z.infer<typeof ItineraryFlightItemSchema>

export const ItineraryDayPlaceSchema = z.object({
  id: z.string(),
  name: z.string().nullish(),
  address: AddressFormSchema.nullish(),
  coordinates: CoordinatesSchema.nullish(),
  sourceId: z.string().nullish(),
  source: z.enum(['manual', 'foursquare', 'viator']).nullish(),
  photoSrc: z.string().nullish(),
  externalUrl: z.string().nullish(),
  comments: z.array(ItineraryPlanCommentSchema).default([]),
})

const ItineraryDayPlanAddressSchema = z
  .object({ type: z.literal('address') })
  .merge(ItineraryDayPlaceSchema)
export type ItineraryDayPlanAddress = z.infer<
  typeof ItineraryDayPlanAddressSchema
>
const ItineraryDayPlanPlaceSchema = z
  .object({ type: z.literal('place') })
  .merge(ItineraryDayPlaceSchema)
export type ItineraryDayPlanPlace = z.infer<typeof ItineraryDayPlanPlaceSchema>
const ItineraryDayPlanProductSchema = z
  .object({ type: z.literal('product') })
  .merge(ItineraryDayPlaceSchema)
export type ItineraryDayPlanProduct = z.infer<
  typeof ItineraryDayPlanProductSchema
>
const ItineraryDayPlanFlightSchema = z
  .object({ type: z.literal('flight') })
  .merge(ItineraryFlightSchema)
export type ItineraryDayPlanFlight = z.infer<
  typeof ItineraryDayPlanFlightSchema
>
export const ItineraryDayPlanSchema = z.discriminatedUnion('type', [
  ItineraryDayPlanAddressSchema,
  ItineraryDayPlanPlaceSchema,
  ItineraryDayPlanProductSchema,
  ItineraryDayPlanFlightSchema,
])
export type ItineraryDayPlan = z.infer<typeof ItineraryDayPlanSchema>

export const ItineraryDayPlanRecordSchema = createOrderedRecordSchema([
  ItineraryDayPlanAddressSchema,
  ItineraryDayPlanPlaceSchema,
  ItineraryDayPlanProductSchema,
  ItineraryDayPlanFlightSchema,
])
export type ItineraryDocumentDayPlan = z.infer<
  typeof ItineraryDayPlanRecordSchema
>
export const ItineraryDayPlanItemSchema =
  ItineraryDayPlanRecordSchema.valueSchema
export type ItineraryDayPlanItem = z.infer<typeof ItineraryDayPlanItemSchema>

const ItineraryDaySchema = z
  .object({
    dayKey: z.string().nullish(),
    title: z.string(),
    description: z.string().nullish(),
    plans: ItineraryDayPlanRecordSchema.nullish(),
    planSize: z.number().nullish(),
    note: z.string().nullish(),
  })
  .transform(data => ({
    ...data,
    dayKey: Object.values(data.plans ?? {}).reduce(
      (key, { comments, ...plan }) => key + objectHash(plan),
      '',
    ),
    planSize: Object.keys(data.plans ?? {}).length,
  }))
export type ItineraryDocumentDay = z.infer<typeof ItineraryDaySchema>

const ItineraryCheckItemSchema = z.object({
  id: z.string(),
  label: z.string(),
  checked: z.boolean().nullish().default(false),
})
export const ItineraryCheckListRecordSchema = createOrderedRecordSchema(
  ItineraryCheckItemSchema,
)
export type ItineraryDocumentCheckList = z.infer<
  typeof ItineraryCheckListRecordSchema
>
export type ItineraryCheckItem = z.infer<
  typeof ItineraryCheckListRecordSchema.valueSchema
>

export const ItineraryDocumentStateSchema = z.object({
  header: ItineraryHeaderSchema.nullish(),
  metadata: ItineraryMetadataSchema.nullish(),

  createdBy: z.string(),
  createdAt: z.number(),
  visibility: z.nativeEnum(ItineraryVisibility),

  updatedBy: z.string().nullish(),
  updatedAt: z.number().nullish(),

  itinerary: z.object({
    flights: ItineraryFlightRecordSchema.nullish(),
    days: z.array(ItineraryDaySchema).nullish(),
    checkList: z
      .object({
        checkListKey: z.string().nullish(),
        list: ItineraryCheckListRecordSchema.nullish(),
      })
      .transform(data => ({
        ...data,
        checkListKey: Object.values(data.list ?? {}).reduce(
          (key, listItem) => key + objectHash(listItem),
          '',
        ),
      }))
      .nullish(),
  }),
})
export type ItineraryDocumentState = z.infer<
  typeof ItineraryDocumentStateSchema
>

/**
 * Form Schemas
 */

export const ItineraryDocumentStateWithIdSchema =
  ItineraryDocumentStateSchema.merge(WithMongoIdSchema)
export type ItineraryDocumentStateWithId = z.infer<
  typeof ItineraryDocumentStateWithIdSchema
>

export const ItineraryDocumentCreateSchema = ItineraryDocumentStateSchema.omit({
  header: true,
  createdAt: true,
  updatedBy: true,
  updatedAt: true,
  itinerary: true,
}).extend({
  header: z.object({
    title: z.string(),
  }),
  itinerary: z
    .object({
      days: z.array(ItineraryDaySchema),
    })
    .optional(), // not nullish because /itinerary/create is over HTTP and Mongo type is partial
})
export type ItineraryDocumentCreate = z.infer<
  typeof ItineraryDocumentCreateSchema
>

export const ItineraryDocumentStateUpdateSchema =
  ItineraryDocumentStateSchema.partial().merge(WithMongoIdSchema)
export type ItineraryDocumentStateUpdate = z.infer<
  typeof ItineraryDocumentStateUpdateSchema
>

export const ItineraryEditRequestSchema = z
  .object({
    shareId: z.string().nullish(),
    document: ItineraryDocumentStateUpdateSchema,
  })
  .merge(GenericEventDataSchema)
export type ItineraryEditRequest = z.infer<typeof ItineraryEditRequestSchema>
